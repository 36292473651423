<template>
    <component
        v-bind="data"
        v-if="data.__typename"
        :is="callComponent"
        :key="`dynamic-component-${componentKey}`"
        :modifier="modifier"
        @mounted="$emit('mounted')"
    />
</template>

<script>
import { v5 as uuidv5 } from 'uuid';
const UUID_NAMESPACE = '490c2094-3d74-11ed-b878-0242ac120002';
export default {
    name: 'Parse',
    computed: {
        callComponent() {
            let component;
            switch (this.data.__typename) {
                case 'CustomImageObjectType':
                    component = 'ImageObjectType';
                    break;
                default:
                    component = this.data.__typename.replace('BaseTier', 'Tier');
            }
            const data = this.data;
            return () =>
                import(`@parse/${component}.vue`).catch(() => {
                    return {
                        name: 'FallbackErrorComponent',
                        render() {
                            return `<details>
                                    <summary>${component} not found</summary>
                                    <pre>
                                        <code>${JSON.stringify(data, null, 2)}</code>
                                    </pre>
                                </details>`;
                        },
                    };
                });
        },
        componentKey() {
            // Generate a UUID for components based on the provided data (as string)
            // to avoid key duplication and to assist in component caching
            return uuidv5(JSON.stringify(this.data), UUID_NAMESPACE);
        },
    },
    props: {
        data: {
            type: Object,
        },
        modifier: {
            type: [String, Array],
        },
    },
};
</script>
