import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteCustomImageObjectType from '@fragment/shared/CompleteCustomImageObjectType';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteFeaturedResourceTier on FeaturedResourceTier {
        blockStyle
        blockTail
        title
        subtitle
        displayType
        blockTail
        blockStyle
        backgroundImage {
            ...CompleteCustomImage
        }
        resource {
            ...PagePreview
            firstThreeFeaturedItems {
                id
                title
                subtitle
                description
                url
                primaryImage {
                    ...CompleteCustomImageObjectType
                }
                thumbnailImage {
                    ...CompleteCustomImageObjectType
                }
                moeThumbnail {
                    ...CompleteCustomImageObjectType
                }
                resourceTypes {
                    id
                    name
                }
            }
        }
    }
    ${CompleteCustomImage}
    ${PagePreview}
    ${CompleteCustomImageObjectType}
`;
