import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteCorePracticeDomain from '@fragment/pages/CompleteCorePracticeDomain';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import CompleteSectionTier from '@fragment/shared/CompleteSectionTier';
import CompleteSectionWithSubsectionTier from '@fragment/shared/CompleteSectionWithSubsectionTier';
import ProfessionalLearningPreview from '@fragment/previews/ProfessionalLearningPreview';
import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import CompleteCustomImageObjectType from '@fragment/shared/CompleteCustomImageObjectType';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteResource on Resource {
        id
        noIndex
        access
        url
        title
        subtitle
        description
        access
        primaryImage {
            ...CompleteCustomImage
        }
        thumbnailImage {
            ...CompleteCustomImage
        }
        resourceTypes {
            id
            name
        }
        corePractices {
            id
            title
        }
        corePracticeDomains {
            ...CompleteCorePracticeDomain
        }
        topics {
            id
            name
        }
        audiences {
            id
            name
        }
        purposes {
            id
            name
        }
        subjects {
            id
            name
        }
        moeThumbnail {
            ...CompleteCustomImage
        }
        moeTypes {
            id
            name
        }
        moeSchool {
            name
            url
            location {
                city
                state
            }
            hasProfilePage
        }
        moeSubjects {
            id
            name
        }
        moeFormats {
            id
            name
        }
        curriculumComponents
        gradeNames: grades
        authors {
            ...PagePreview
        }
        shopifyUrl
        ebookLinks {
            ...CompleteLinkBlock
        }
        sectionSubnavigation
        content {
            ...CompleteSectionTier
            ...CompleteSectionWithSubsectionTier
        }
        similarResources {
            ...PagePreview
        }
        relatedProfessionalLearningCatalogEntries {
            ...ProfessionalLearningPreview
        }
        downloadables {
            ...CompleteDownloadables
        }
        firstThreeFeaturedItems {
            id
            title
            subtitle
            description
            urlPath
            thumbnailImage {
                ...CompleteCustomImageObjectType
            }
        }
        seoOgImage {
            ...CompleteCustomImage
        }
        collections {
            id
            title
        }
    }
    ${CompleteCustomImage}
    ${CompleteCorePracticeDomain}
    ${PagePreview}
    ${CompleteLinkBlock}
    ${CompleteSectionTier}
    ${CompleteSectionWithSubsectionTier}
    ${ProfessionalLearningPreview}
    ${CompleteDownloadables}
    ${CompleteCustomImageObjectType}
`;
