import Vue from 'vue';

const DEFAULT_SLOP = 0;
const DEFAULT_SIZE = 30;
const FILTERABLE_KEYS = {
    contentType: 'content_type',
    excludeSchoolFromForms: 'cms_school__exclude_from_forms',
};

export default (context) => {
    const { OPENSEARCH_API_URL } = context.$config;
    const api = {
        abortController: null,
        getPhraseConfig(queryObject, { withSlop = false, operator = null } = {}) {
            const phraseConfig = {};
            Object.keys(queryObject).forEach((key) => {
                phraseConfig[key] = {
                    query: queryObject[key],
                };

                if (withSlop) {
                    phraseConfig[key].slop = DEFAULT_SLOP;
                }

                if (operator) {
                    phraseConfig[key].operator = operator;
                }
            });
            return phraseConfig;
        },

        getWildcardConfig(queryObject) {
            const wildcardConfig = {};
            Object.keys(queryObject).forEach((key) => {
                wildcardConfig[key] = {
                    value: `*${queryObject[key]}*`,
                    case_insensitive: true,
                };
            });
            return wildcardConfig;
        },

        getFilters(queryMeta) {
            const filters = [];
            Object.keys(queryMeta)
                .filter((key) => Object.keys(FILTERABLE_KEYS).includes(key))
                .forEach((key) => {
                    const filterKey = FILTERABLE_KEYS[key];
                    const filterValue = queryMeta[key];
                    const termFilter = {
                        [filterKey]: filterValue,
                    };
                    filters.push({ term: termFilter });

                    // TODO: This is probably not the best place to do this, but for now it
                    // should be good. When we start expanding on the opensearch functionality
                    // this should be refactored.
                    if (filterKey === 'content_type' && filterValue === 'cms.School') {
                        filters.push({
                            term: {
                                cms_school__exclude_from_forms: false,
                            },
                        });
                    }
                });

            return filters;
        },

        buildQueryBody(queryObject, queryMeta) {
            const filters = api.getFilters(queryMeta);

            return {
                size: queryMeta.size || DEFAULT_SIZE,
                query: {
                    bool: {
                        should: [
                            {
                                match: api.getPhraseConfig(queryObject, { operator: 'and' }),
                            },
                            {
                                wildcard: api.getWildcardConfig(queryObject),
                            },
                        ],
                        filter: filters,
                    },
                },
            };
        },

        abortPreviousRequest() {
            if (api.abortController) {
                api.abortController.abort();
            }
            api.abortController = new AbortController();
        },

        async request(queryBody) {
            api.abortPreviousRequest();
            const response = await fetch(OPENSEARCH_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(queryBody),
                signal: api.abortController.signal,
            });
            return await response.json();
        },

        async phraseSearch(queryObject, queryMeta = {}) {
            let hits = [];

            try {
                const queryBody = api.buildQueryBody(queryObject, queryMeta);
                const response = await api.request(queryBody);
                hits = response.hits.hits.filter((hit) => hit._score > 0);
            } catch {
                hits = [];
            }
            return { hits };
        },
    };

    Vue.prototype.$opensearch = {
        phraseSearch: api.phraseSearch,
    };
};
