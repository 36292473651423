<template>
    <div class="autocomplete-dropdown" :class="{ '--allow-create': allowCreate }">
        <input type="text" @keyup="enterMonitor" v-model="currentSearch" :placeholder="fieldInfo.emptyLabel" />
        <div class="dropdown-content" v-if="shouldShowOptions">
            <div
                class="dropdown-content__option"
                @mousedown="selectOption(option)"
                @keyup.enter="selectOption(option)"
                v-for="(option, index) in filteredOptions"
                :key="index"
                v-html="getOptionString(option.name)"
                tabindex="0"
            ></div>
        </div>
        <div
            v-if="currentSearch"
            class="clear-dropdown"
            @click="clearOption"
            tabindex="0"
            @keydown.enter="clearOption"
            ref="clearDropdown"
        >
            <Icon icon="close" class="clear-icon" />
        </div>
    </div>
</template>
<script>
import Icon from '@module/Icon';

export default {
    props: {
        fieldInfo: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        opensearchHits: [],
        opensearchTimeout: null,
        currentSearch: '',
        shouldShowOptions: false,
        selected: {},
    }),
    computed: {
        allowCreate() {
            return /true/i.test(this.fieldInfo.attrs?.allowCreate);
        },
        useOpensearch() {
            return !!this.fieldInfo.attrs?.opensearch;
        },
        opensearchContentType() {
            return this.fieldInfo.attrs?.opensearch;
        },
        opensearchOptions() {
            const options = this.opensearchHits.map((hit) => {
                const source = hit._source;
                const name = source.title;

                return {
                    id: source.pk,
                    name,
                };
            });

            if (this.allowCreate) {
                options.push({
                    id: this.fieldInfo.attrs.createValue,
                    name: this.fieldInfo.attrs.createLabel,
                });
            }

            return options;
        },
        filteredOptions() {
            if (this.currentSearch.length < 2) return this.fieldInfo.options;
            if (this.useOpensearch && this.opensearchOptions.length) {
                return this.opensearchOptions;
            } else {
                const searchLower = this.currentSearch.toLowerCase();
                return this.fieldInfo.options.filter((option) => {
                    const nameLower = option.name.toLowerCase();
                    const locationStart = nameLower.indexOf('(');
                    const nameString = locationStart !== -1 ? nameLower.substring(0, locationStart) : nameLower;

                    return nameString.includes(searchLower) ? option : option.id === 'not_found';
                });
            }
        },
    },
    watch: {
        currentSearch(newSearchString) {
            if (newSearchString.length > 2) {
                this.shouldShowOptions = true;
            } else {
                this.shouldShowOptions = false;
            }

            if (this.useOpensearch) {
                clearTimeout(this.opensearchTimeout);
                this.opensearchTimeout = setTimeout(() => this.getOpenSearchOptions(), 50);
            }
        },
        selected(newSelectedObject) {
            if (newSelectedObject.id) {
                this.shouldShowOptions = false;
            }
        },
    },

    methods: {
        getOptionString(name) {
            if (!this.currentSearch || name.startsWith('+')) return name;

            let processedName = name;
            this.currentSearch
                .toLowerCase()
                .split(/\s+/)
                .forEach((word) => {
                    if (!word) return;

                    const cleanWord = word.replace(/[^0-9a-z-]/gi, '');
                    const indexOfMatch = processedName.toLowerCase().indexOf(cleanWord.toLowerCase());

                    if (indexOfMatch === -1) return;

                    processedName = [
                        processedName.substring(0, indexOfMatch),
                        "<span class='option--highlighted'>",
                        processedName.substring(indexOfMatch, indexOfMatch + cleanWord.length),
                        '</span>',
                        processedName.substring(indexOfMatch + cleanWord.length),
                    ].join('');
                });

            return processedName;
        },
        selectOption(option) {
            this.selected = option;
            this.exit();
        },
        exit() {
            if (!this.selected.id) {
                this.selected = {};
                this.currentSearch = '';
            } else {
                const tmpEl = document.createElement('div');
                tmpEl.innerHTML = this.selected.name;
                this.currentSearch = tmpEl.textContent || tmpEl.innerText;
            }
            this.$refs.clearDropdown && this.$refs.clearDropdown.focus();
            this.$emit('selected', this.selected);
        },
        // allows you to select the top option with "enter"
        enterMonitor: function (event) {
            if (event.key === 'Enter' && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
        },
        clearOption() {
            this.currentSearch = '';
            this.selected = {};
            this.$emit('selected', this.selected);
        },
        async getOpenSearchOptions() {
            if (this.currentSearch.length < 2) return;
            const searchLower = this.currentSearch.toLowerCase();
            const searchResponse = await this.$opensearch.phraseSearch(
                {
                    title: searchLower,
                },
                {
                    contentType: this.opensearchContentType,
                },
            );

            this.opensearchHits = searchResponse.hits;
        },
    },
    mounted() {
        const selectedId = JSON.parse(localStorage.getItem('formData'))?.schooldistrictorganization;

        if (selectedId) {
            const selectedOption = this.filteredOptions.find((option) => option.id === selectedId) || {};
            this.selectOption(selectedOption);
        }

        if (this.fieldInfo.value) {
            const selectedOption = this.filteredOptions.find((option) => option.id === this.fieldInfo.value) || {};
            this.selectOption(selectedOption);
        }
    },
    components: {
        Icon,
    },
};
</script>
